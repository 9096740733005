import { get } from 'lodash'
import AppButton from '@theme/components/atom/AppButton'
import AppTitle from '@theme/components/atom/AppTitle'
import Modal from '@theme/components/utils/Modal'

export default {
  components: {
    AppButton,
    AppTitle,
    Modal,
  },
  mounted() {
    this.init()
  },
  computed: {
    openModal() {
      return this.$store.state.globals.redirectModalOpen
    },
  },
  methods: {
    init() {
      if (this.openModal) {
        this.$refs.RedirectModal.show()
      }
    },
    click() {
      const url = get(this.$themeSettings, `components.RedirectModal.url[${this.$i18n.locale}]`, false)
      const target = this.$themeSettings.components.RedirectModal.target || '_self'
      if (url) {
        window.open(url, target).focus()
      }
      this.hide()
    },
    hide() {
      this.$emit('hide')
      this.$store.commit('globals/SET_REDIRECT_MODAL_STATE', false)
    },
  },
  watch: {
    openModal: function(val) {
      this.$nextTick(() => {
        this.init()
      })
    },
  },
}
